import { fetchPost } from '../index';
// 查询Dealer table数据接口
export const getVlList = (
  params = {
    retailStartDate: '',
    retailEndDate: '',
    bu: '',
    buId: '',
    xrate: '',
    flag: ''
  }
): Promise<any> => {
  return fetchPost(`/rvapi/report/vl/list`, params);
};
