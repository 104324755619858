
//http://localhost:8080/#/claim/EnquiryProgram
import { defineComponent, ref, reactive, toRefs, nextTick, computed, watch } from 'vue';
import { getQueryList, deleteInfo } from '@/API/claim/claimQuery';
import { getVlList } from '@/API/salesReporting/VehicleLicenseTrackingReport';
import { getDealerList, getProgramList } from '@/API/salesReporting/claimCheckingReport';
import { message, TreeSelect } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
// import setColumnWidth from "@/utils/claim/setColumnWidth"
import downloadFile from '@/utils/claim/downloadFile';
import moment, { Moment } from 'moment';
import { useStore } from 'vuex';
import useFetch from '@/hooks/useFetch';
import { getUser } from '@/API/claim/delaerClaim/delaer';
import DM from '@/views/SalesReporting/components/downloadModal.vue';
import MultiInput from '@/views/FAQ/components/MultiInput.vue';
import { getDealerByBUID } from '@/API/salesReporting';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';

// import axios from "axios";
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
const tableWidth = window.innerWidth;
const tableWidth2 = (window.innerWidth * 4) / 9;
const tableWidth3 = (window.innerWidth * 5) / 7;
const sunCol1 = reactive([
  { title: 'Region', dataIndex: 'region', width: 100 },
  {
    title: 'Vehicle License Eligible Submission Rate %',
    dataIndex: 'rate',
    width: 200
  },
  { title: 'Eligible Retail No.', dataIndex: 'subCount', width: 200 },
  { title: 'Eligible Vehicle License No.', dataIndex: 'rstCount', width: 200 },
  { title: 'Retail No.', dataIndex: 'retailNo', width: 200 },
  { title: 'Submitted Vehicle License No.', dataIndex: 'svlCount', width: 280 },
  { title: 'Valid Vehicle License Information Submission Rate%', dataIndex: 'isRate', width: 320 }
]);
const sunCol2 = reactive([
  { title: 'Region', dataIndex: 'region', width: 100 },
  {
    title: 'Vehicle License Eligible Submission Rate>=X% Dealer Rate %',
    dataIndex: 'rate',
    width: 250
  },
  { title: 'Eligible Dealer No.', dataIndex: 'allCount', width: 200 },
  {
    title: 'Vehicle License Eligible Submission Rate>=X% Dealer No.',
    dataIndex: 'moreCount',
    width: 250
  },
  {
    title: 'Vehicle License Eligible Submission Rate<X% Dealer No.',
    dataIndex: 'lessCount'
  }
]);
const sunCol3 = reactive([
  { title: 'SubRegion', dataIndex: 'region', width: 100 },
  {
    title: 'Vehicle License Eligible Submission Rate %',
    dataIndex: 'rate',
    width: 200
  },
  { title: 'Eligible Retail No.', dataIndex: 'subCount', width: 200 },
  { title: 'Eligible Vehicle License No.', dataIndex: 'rstCount', width: 200 },
  { title: 'Retail No.', dataIndex: 'retailNo', width: 200 },
  { title: 'Submitted Vehicle License No.', dataIndex: 'svlCount', width: 280 },
  { title: 'Valid Vehicle License Information Submission Rate%', dataIndex: 'isRate', width: 320 }
]);
const sunCol4 = reactive([
  { title: 'SubRegion', dataIndex: 'region', width: 100 },
  {
    title: 'Vehicle License Eligible Submission Rate>=X% Dealer Rate %',
    dataIndex: 'rate',
    width: 250
  },
  { title: 'Eligible Dealer No.', dataIndex: 'allCount', width: 200 },
  {
    title: 'Vehicle License Eligible Submission Rate>=X% Dealer No.',
    dataIndex: 'moreCount',
    width: 250
  },
  {
    title: 'Vehicle License Eligible Submission Rate<X% Dealer No.',
    dataIndex: 'lessCount'
  }
  // { title: "Operation", dataIndex: "checkResult", width: 200, slots: { customRender: "operation" } },
]);
const rawDataCol = reactive([
  { title: 'Dealer Code (Cofico)', dataIndex: 'dealerCode', width: 200 },
  { title: 'Dealer Code (SWT)', dataIndex: 'dealerCodeSwt', width: 200 },
  { title: 'Dealer Name', dataIndex: 'dealerName', width: 200 },
  { title: 'POCC date', dataIndex: 'poccDate', width: 200 },
  { title: 'Dealer Group', dataIndex: 'dealerGroup', width: 200 },
  {
    title: 'Region',
    dataIndex: 'region',
    width: 200
  },
  { title: 'Region_CN', dataIndex: 'regionCn', width: 200 },
  { title: 'SubRegion', dataIndex: 'subRegionEn', width: 200 },
  { title: 'Province', dataIndex: 'provinceCn', width: 200 },
  { title: 'City', dataIndex: 'cityCn', width: 200 },
  { title: 'Year', dataIndex: 'year', width: 200 },
  { title: 'Month', dataIndex: 'month', width: 200 },
  {
    dataIndex: 'rate',
    width: 300,
    slots: {
      title: 'vehicleLicenseEligibleSubmissionRateTitle'
    }
  },
  {
    dataIndex: 'subCount',
    width: 200,
    slots: {
      title: 'eligibleRetailNoTitle'
    }
  },
  {
    dataIndex: 'rstCount',
    width: 200,
    slots: {
      title: 'eligibleVehicleLicenseNoTitle'
    }
  },
  {
    dataIndex: 'svlCount',
    width: 220,
    slots: {
      title: 'submittedVehicleLicenseNoTitle'
    }
  },
  {
    dataIndex: 'retailNo',
    width: 180,
    slots: {
      title: 'retailNoTitle'
    }
  },
  {
    dataIndex: 'isRate',
    width: 380,
    slots: {
      title: 'validVehicleLicenseInformationSubmissionRateTitle'
    }
  },
  {
    dataIndex: 'ipRate',
    width: 380,
    slots: {
      title: 'validVehicleLicenseInformationPassingRateTitle'
    }
  },
  {
    dataIndex: 'unPassCount',
    width: 280,
    slots: {
      title: 'ineligibleVehicleLicenseNoTitle'
    }
  },
  {
    title: 'Final Result_If Eligible Submission',
    dataIndex: 'ifUseful',
    width: 200
  },
  {
    title: 'Special Notes（POCC exemption for X month）',
    dataIndex: 'poccFlag',
    width: 220
  }
]);
const customColumns = [
  {
    key: 'vehicleLicenseEligibleSubmissionRateTitle',
    title: 'Vehicle License Eligible Submission Rate %',
    content: 'Vehicle License Eligible Submission Rate%= Eligible Vehicle License No./Eligible Retail No.	'
  },
  {
    key: 'eligibleRetailNoTitle',
    title: 'Eligible Retail No.',
    content: 'RV模块激励适用性有效零售'
  },
  {
    key: 'eligibleVehicleLicenseNoTitle',
    title: 'Eligible Vehicle License No.',
    content: 'VL模块核查结果有效行驶证	'
  },
  {
    key: 'submittedVehicleLicenseNoTitle',
    title: 'Submitted Vehicle License No.',
    content: 'VL模块已提交行驶证'
  },
  {
    key: 'retailNoTitle',
    title: 'Retail No.',
    content: 'RV模块零售总计'
  },
  {
    key: 'validVehicleLicenseInformationSubmissionRateTitle',
    title: 'Valid Vehicle License Information Submission Rate %',
    content: 'Valid Vehicle License Information Submission Rate% =Submitted Vehicle License No./Retail No.	'
  },
  {
    key: 'validVehicleLicenseInformationPassingRateTitle',
    title: 'Valid Vehicle License Information Passing Rate %',
    content:
      'Valid Vehicle License Information Passing Rate% = Eligible Vehicle License No./Submitted Vehicle License No.	'
  },
  {
    key: 'ineligibleVehicleLicenseNoTitle',
    title: 'Ineligible Vehicle License No.',
    content: 'Ineligible Vehicle License No. = Eligible Retail No. - Eligible Vehicle License No.	'
  }
];
interface TreeDataItem {
  value: string;
  key: string;
  title?: string;
  disabled?: boolean;
  children?: TreeDataItem[];
}
const qmOptions: TreeDataItem[] = [
  {
    title: 'Q1',
    value: 'Q1',
    key: 'Q1',
    children: [
      {
        title: '01',
        value: '01',
        key: '01'
      },
      {
        title: '02',
        value: '02',
        key: '02'
      },
      {
        title: '03',
        value: '03',
        key: '03'
      }
    ]
  },
  {
    title: 'Q2',
    value: 'Q2',
    key: 'Q2',
    children: [
      {
        title: '04',
        value: '04',
        key: '04'
      },
      {
        title: '05',
        value: '05',
        key: '05'
      },
      {
        title: '06',
        value: '06',
        key: '06'
      }
    ]
  },
  {
    title: 'Q3',
    value: 'Q3',
    key: 'Q3',
    children: [
      {
        title: '07',
        value: '07',
        key: '07'
      },
      {
        title: '08',
        value: '08',
        key: '08'
      },
      {
        title: '09',
        value: '09',
        key: '09'
      }
    ]
  },
  {
    title: 'Q4',
    value: 'Q4',
    key: 'Q4',
    children: [
      {
        title: '10',
        value: '10',
        key: '10'
      },
      {
        title: '11',
        value: '11',
        key: '11'
      },
      {
        title: '12',
        value: '12',
        key: '12'
      }
    ]
  }
];

export default defineComponent({
  components: {
    DM,
    MultiInput,
    ExclamationCircleOutlined
  },
  setup() {
    const { state } = useStore();
    const searchCon = ref();
    const tableHeight = ref();
    const tableHeight2 = ref();
    const tableBoxHeight = ref();
    const period = ref<Moment[]>([]);

    const inputPersent = ref<number>(95);

    //显示的表格数据
    const parameterList = ref<any>({
      bu: null,
      period: [],
      dealerCode: {
        label: 'Dealer Code',
        dealerCode: [],
        batchQueryTitle: 'Dealer Code Batch Enquiry',
        batchQueryListTitle: 'Dealer Code List'
      },
      dealerName: []
    });

    const dealerNameCnList = ref<any[]>();
    const getDealerInfos = () => {
      const index = buList.value.findIndex((item: any) => item.nameEn === parameterList.value.bu);
      const buId = buList.value[index]?.id;
      getDealerByBUID({ buId }).then((res: any) => {
        const nameCn: any[] = [];
        res.forEach((dealer: any) => {
          nameCn.push(dealer.dealerInfo.dealerNameCn);
        });
        dealerNameCnList.value = nameCn;
      });
    };

    watch(
      () => parameterList.value.bu,
      (newVal: any) => {
        if (newVal) {
          getDealerInfos();
        }
      }
    );

    const changeDealerCode = (vals: any) => {
      parameterList.value.dealerCode.dealerCode = vals;
    };

    let buCopy = '';
    //获取bu选项
    const buList = computed(() =>
      state.user.buList
        .filter((bu: any) => {
          return bu.parentId === null;
        })
        .filter((bu: any) => {
          return bu.nameEn !== 'Companycar' && bu.nameEn !== 'CPO';
        })
    );
    const doGetBuList = () => {
      if (buList.value.length === 0) return;
      const mbIndex = buList.value.findIndex((i: any) => {
        return i.nameEn === 'MBPC';
      });
      const initIndex = mbIndex === -1 ? 0 : mbIndex;
      const value = buList.value[initIndex].nameEn;
      parameterList.value.bu = value;
      buCopy = value;
    };

    //重置
    const handlerReset = () => {
      parameterList.value.bu = buCopy;
      parameterList.value.dealerCode.dealerCode = [];
      parameterList.value.dealerName = [];
      period.value = [];
      Object.assign(parameterDataLst.value, {
        retailStartDate: '',
        retailEndDate: '',
        bu: '',
        buId: '',
        xrate: '',
        flag: '',
        dealerCodes: [],
        dealerNameCns: []
      });
    };

    //整理获取parameter
    const parameterDataLst = ref<any>({
      retailStartDate: '',
      retailEndDate: '',
      bu: '',
      buId: '',
      xrate: '',
      flag: '',
      dealerCodes: [],
      dealerNameCns: []
    });

    const dogetParameter = (flag: any) => {
      parameterDataLst.value.bu = parameterList.value.bu;
      buList.value.forEach((bui: any, buind: any) => {
        if (bui.nameEn === parameterList.value.bu) {
          parameterDataLst.value.buId = bui.id;
        }
      });
      parameterDataLst.value.dealerCodes =
        parameterList.value.dealerCode.dealerCode.length === 1 && parameterList.value.dealerCode.dealerCode[0] === ''
          ? []
          : parameterList.value.dealerCode.dealerCode;
      parameterDataLst.value.dealerNameCns = parameterList.value.dealerName;
      parameterDataLst.value.xrate = inputPersent.value;
      parameterDataLst.value.flag = flag;
    };

    const periodChange = (val: Moment[]) => {
      parameterDataLst.value.retailStartDate = val[0];
      parameterDataLst.value.retailEndDate = val[1];
    };

    nextTick(() => {
      (document.getElementsByClassName('table-box')[0] as any).style.height =
        window.innerHeight - 180 - searchCon.value.$el.scrollHeight + 'px';
      const height = (document.getElementsByClassName('table-box')[0] as any).style.height;
      const heightNum = parseInt(height.substr(0, height.indexOf('px')));
      tableHeight.value = heightNum - 150;
      tableHeight2.value = heightNum - 190;
      tableBoxHeight.value = heightNum - 50;
    });
    const dealerByRegionData = ref<any[]>([]);
    const dealerBySubRegionData = ref<any[]>([]);
    const smtByRegionData = ref<any[]>([]);
    const smtBySubRegionData = ref<any[]>([]);
    const rowdataData = ref<any[]>([]);
    const doGetDealerData = () => {
      if (!parameterDataLst.value.retailStartDate || !parameterDataLst.value.retailEndDate) {
        message.error('Retail period (Year) must be selected!');
        return;
      }
      dogetParameter('');
      console.log('parameterDataLst.value', parameterDataLst.value);
      getVlList(parameterDataLst.value).then((res) => {
        dealerByRegionData.value = res.dealerByRegion;
        dealerBySubRegionData.value = res.dealerBySubRegion;
        smtByRegionData.value = res.smtByRegion;
        smtBySubRegionData.value = res.smtBySubRegion;
        rowdataData.value = res.rowdata;
      });
    };
    const dogetAllData = () => {
      doGetDealerData();
    };
    const initPage = () => {
      doGetBuList();
    };
    initPage();
    //切换tab
    const tabN = ref<any>('Summary View');
    const tabChange = (e: any) => {
      if (e === '1') {
        tabN.value = 'Summary View';
      } else if (e === '2') {
        tabN.value = 'Raw Data';
      }
    };

    const downloadTableData = [
      {
        file: 'Raw Data',
        sign: 'raw'
      },
      {
        file: 'Report Data',
        sign: 'overview'
      }
    ];

    const downloadTypeVisible = ref(false);

    const download = () => {
      if (!parameterDataLst.value.retailStartDate || !parameterDataLst.value.retailEndDate) {
        message.error('Retail period (Year) must be selected!');
        return;
      }
      downloadTypeVisible.value = true;
    };
    const closeDownloadTypeModal = () => {
      downloadTypeVisible.value = false;
    };

    //下载
    const handlerDownload = (selectedData: any) => {
      dogetParameter('');
      let downloadType;
      if (selectedData.length > 1) {
        downloadType = 'zip';
      } else {
        downloadType = selectedData[0].sign;
      }
      parameterDataLst.value.flag = downloadType;
      const paramsConfig = {
        url: `/rvapi/report/vl/download/overall`,
        method: 'post',
        params: parameterDataLst.value
      };
      downloadFile(paramsConfig, 'application/vnd-excel; char-set=UTF-8').then(() => {
        downloadTypeVisible.value = false;
      });
    };

    return {
      download,
      downloadTypeVisible,
      closeDownloadTypeModal,
      downloadTableData,
      dealerByRegionData,
      dealerBySubRegionData,
      smtByRegionData,
      smtBySubRegionData,
      rowdataData,
      inputPersent,

      period,
      periodChange,
      tabN,
      tabChange,
      handlerDownload,
      dogetAllData,
      dogetParameter,
      buList,
      handlerReset,
      doGetBuList,
      qmOptions,
      parameterList,
      doGetDealerData,
      searchCon,
      tableWidth,
      tableWidth2,
      tableWidth3,
      tableBoxHeight,
      tableHeight,
      tableHeight2,
      sunCol1,
      sunCol2,
      sunCol3,
      sunCol4,
      rawDataCol,
      SHOW_PARENT,
      changeDealerCode,
      dealerNameCnList,
      customColumns
    };
  }
});
